import React from 'react';
import { useLocation } from 'react-router-dom';

import useProjectPlan from '@core/hooks/useProjectPlan';
import { useProjectStore } from '@core/store';

/**
 * Renders the custom HTML footer on the home page.
 */
export default function CustomHomeFooter() {
  const { search } = useLocation();
  const [footerHtml] = useProjectStore(s => [s.data.appearance.custom_code.html.home_footer]);
  const { planFeatures } = useProjectPlan();

  const query = new URLSearchParams(search);
  const disableCustom = query.get('disableCustom') === 'true';

  const hasAccess = planFeatures.whitelabel;

  return hasAccess && footerHtml && !disableCustom ? <footer dangerouslySetInnerHTML={{ __html: footerHtml }} /> : null;
}
